'use client';

import Button from '@src/components/coreUI/button';
import { APP_ROUTE } from '@src/constants/routes';
import { useRouter } from 'next/navigation';
import React from 'react';

function NotFound() {
  const { push } = useRouter();
  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        <h2 className="text-[80px] font-semibold text-center text-blue-dark mb-2">
          404
        </h2>
        <p className="text-center text-4xl text-gray-dark/80 mb-5">
          Opps! Page not found
        </p>
        <p className="text-center text-gray-dark mb-6">
          Either somthing went wrong or the page doesn&apos;t exist anymore.
        </p>
        <Button
          variant="blue"
          size="md"
          extraClasses="m-auto"
          onClick={() => push(APP_ROUTE.HOME)}
        >
          Go Home
        </Button>
      </div>
    </div>
  );
}

export default NotFound;
